import { FC, PropsWithChildren, ReactNode } from "react";
import Seo from "components/base/seo/Seo";

const CommonLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <Seo />
            {children}
        </>
    );
};

export const getCommonLayout = (page: ReactNode) => <CommonLayout>{page}</CommonLayout>;
