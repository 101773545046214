import { FC } from "react";
import Link from "next/link";
import { TranslationScopes } from "@finbackoffice/enums";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { formatAppNameText } from "@finbackoffice/fe-core";
import classNames from "classnames";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import styles from "./not-found.module.sass";

type IProps = {
    statusCode: number;
};

export const NotFound: FC<IProps> = ({ statusCode }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    return (
        <ScrollComponent containerClass={styles.notFoundContainer}>
            <section className={styles.notFoundPage}>
                <div>
                    <div className={styles.leftBubble}>
                        <span>
                            <strong>{statusCode}</strong>
                            <Translate
                                tid="notFound_error"
                                namespace={TranslationScopes.NotFound}
                            />
                        </span>
                        <span>
                            <Translate tid="notFound_head" namespace={TranslationScopes.NotFound} />
                        </span>
                        <p>
                            <Translate tid="notFound_txt" namespace={TranslationScopes.NotFound} />
                        </p>
                    </div>
                    {COMMON_SITE_CONFIGS.skinVersion === SkinVersions.Xrpbet ? (
                        <Img
                            source={`${ASSETS_URL}/common/desktop/not-found/basketball.webp`}
                            alt="Not Found"
                            width={424}
                            height={332}
                        />
                    ) : (
                        <Svg
                            src={`/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/desktop/logo.svg`}
                            wrapper="span"
                            className={classNames(
                                styles.notFoundLogo,
                                styles[`sr-${formatAppNameText(COMMON_SITE_CONFIGS.appName)}`],
                            )}
                        />
                    )}
                </div>
            </section>

            {statusCode !== 500 && (
                <Link href="/" className={styles.goToHome}>
                    <Translate tid="notFound_go_home" namespace={TranslationScopes.NotFound} />
                </Link>
            )}
        </ScrollComponent>
    );
};
